import { useSelector } from "react-redux";
import * as payTypes from './payTypes';

export default function useIsIamNotAttendingPermissible() {

    const { friends } = useSelector(state => state.event.friends);

    if (friends?.some(f => f.kid || f.pay === payTypes.PAYTYPE_FRIENDPAY)) {
        console.log('I am not attending not applicable');
        return false;
    }

    return true;
}
