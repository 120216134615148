import { useSelector } from 'react-redux';
import * as payTypes from './payTypes';

export default function useIsAllPricesSet() {

    const { friends, prices, ticket, iamnotattending } = useSelector(state => state.event.friends);
    
    if (!iamnotattending) {
        const { type, price } = ticket;
        if (type === null || price === null) {
            return false;
        }        
    }

    const tickets = [...friends.map(f => ({...f.ticket, pay: f.pay, user_id: f.user.id}))];

    if (!prices || !tickets) {
        return false;
    }

    return tickets.every(t => t.price === 0 || !!+t.price || t.user_id === 0 );
}