import React from 'react';
import { PrimaryButton } from 'components/buttons/Button';
import './style.scss';
import { useTranslation } from 'react-i18next';

export default ({ retry, ...props }) => {

    const { t } = useTranslation('error');

    return (<div className="zt-error-message">
        <h4>{t('label', 'Error:')}</h4>
        <div className='zt-error-message__message zt-nav light'>{t('description', 'Something is going wrong, please try again later..')}</div>
        <PrimaryButton error={t('subdescription', 'If error is not gone, please contact the support')} onClick={() => retry && retry()}>{t('button', 'Retry')}</PrimaryButton>
    </div>);
}