import React, { useEffect, useMemo, useState } from 'react';
import Button, { PrimaryButton } from 'components/buttons/Button';
import PayModeInfoModal from './components/PaymentInfoModal';
import RadioGroup from 'components/Inputs/RadioGroup';
import { Info } from 'components/Icons/Glyphs';
import * as payTypes from './../payTypes';
import FRIENDMODE from './mode';
import { useSelector } from 'react-redux';

const PayModeSelector = ({ mode, friend, setValue, tt, onShowModal, ...props }) => {

    const i_pay_label = tt('inputs.paytype.i_pay', 'I Pay Now');
    const friend_pays_label = tt('inputs.paytype.friend_pays', 'My Friend Pays');

    const onChange = value => setValue('paytype', value);
    const defaultValue = friend?.pay;
    const name = 'paytype';

    useEffect(() => {
        onChange(defaultValue);
    }, []);


    const { iamnotattending } = useSelector(state => state.event.friends);
  
    const items = useMemo(() => {
        return [
            {id: payTypes.PAYTYPE_IPAY, label: i_pay_label}, 
            {id: payTypes.PAYTYPE_FRIENDPAY, label: friend_pays_label}
        ].filter(m => ({
            [FRIENDMODE.UNSET]: false,
            [FRIENDMODE.KID]: m.id === payTypes.PAYTYPE_IPAY,
            [FRIENDMODE.EXISTED]: true,
            [FRIENDMODE.NEWUSER]: true//m.id === payTypes.PAYTYPE_FRIENDPAY
        }[mode] || false))?.filter( m => iamnotattending ? m.id === payTypes.PAYTYPE_IPAY : true);
    }, [mode, iamnotattending]);

    if (!mode || mode === FRIENDMODE.LOADING || !items) {
        return null;
    }
    // <PrimaryButton onClick={() => onShowModal && onShowModal()} icon={Info} className='zt-event-buy__add-friends__friend__form__controlls__info'  />
    return <RadioGroup {...{name, onChange, defaultValue, items}} className='zt-event-buy__add-friends__friend__form__controlls__payselect' />;

}


export default props => {

    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <PayModeSelector {...props} onShowModal={() => setShowModal(true)} />
            {showModal && <PayModeInfoModal onHide={() => setShowModal(false)} />}
        </>
    );
}