import React, { useState } from "react";
import RadioGroup from "components/Inputs/RadioGroup";
import { Trans } from "react-i18next";
import './styles.scss';

export const TYPE_PASSPORT = 'passport';
export const TYPE_ID_CARD = 'id_card';

const Code = ({ children }) => (
    <div className='zt-document-samples__content__code '>
        <span className='light'>{children}</span>
    </div>
);

const S = ({ children }) => (
    <span style={{color: '#b02518', backgroundColor: 'white'}}>{children}</span>
);

const S1 = ({ children }) => (
    <span style={{color: '#3970a3', backgroundColor: 'white'}}>{children}</span>
);

const MRZPassport = ({ children }) => (
    <Code>
        {`P<SWEZIPPERTIC<<JOHN<<<<<<<<<<<<<<<<<<<<<<<<`}<br/>
        <S>{`ABC456789`}</S>{`6`}<S1>NOR</S1>{`1105094M2805014<<<<<<<<<<<<<<02`}
    </Code>
);

const Passport = ({ t }) => (
<div>
    <ol type="1">
        <li><Trans {...{t, i18nKey:'using_document.document.info.passport.p1'}}>Find the passport number on your passport.</Trans></li>
        <li>
            <Trans {...{t, i18nKey:'using_document.document.info.passport.p2'}} components={{ S: <S />, S1: <S1 />, MRZPassport: <MRZPassport /> }}>
            Make sure the passport number <S>(in red below)</S> can be found in the Machine Readable Zone (MRZ):
            <MRZPassport />
            Make sure your nationality <S1>(in blue above)</S1> is shown in your passport's MRZ.
            </Trans>
        </li>
        <li><Trans {...{t, i18nKey:'using_document.document.info.passport.p3'}} components={{ S: <S />}}>Fill in your passport number: <S>ABC456789</S> (max 9 digits)</Trans></li>
    </ol>
</div>);

const MRZDocument = ({ children }) => (
    <Code>
        {`I<SWE`}<S>{`ABC456789`}</S>{`6<<<<<<<<<<<<<<<`}<br/>
        {`1105094M2805014`}<S1>NOR</S1>{`<<<<<<<<<<<2`}<br/>
        {`ZIPPERTIC<<JOHN<<<<<<<<<<<<<<<`}
    </Code>
);

const IDCard = ({ t }) => (
<div>
    <ol type="1">
        <li>
            <Trans {...{t, i18nKey:'using_document.document.info.document.p1'}} components={{ S: <S />, S1: <S1 />, MRZDocument: <MRZDocument /> }}>
            Find your document number <S>(in red below)</S> in the Machine Readable Zone (MRZ) on your national ID-Card:
            <MRZDocument />
            Make sure your nationality <S1>(in blue above)</S1> is shown in your document's MRZ.
            </Trans>
        </li>
        <li><Trans {...{t, i18nKey:'using_document.document.info.document.p2'}} components={{ S: <S />}}>Fill in your document number: <S>ABC456789</S> (max 9 digits)</Trans></li>
    </ol>
</div>);

export default ({ mode, t, type, setType, ...props }) => {

    const items = [TYPE_PASSPORT, TYPE_ID_CARD].map(key => ({id: key, label: t(`using_document.document.inputs.type.types.${key}.label`, '-')}));

    return (
        <div class="zt-register-info">
            <RadioGroup
                required
                name='type'                                    
                // label={t('using_document.document.inputs.type.label', 'Choose a Document type')} 
                defaultValue={type}
                onChange={value => setType(value)}
                items={items} />
            {{
                [TYPE_PASSPORT]: <Passport {...{t}} />,
                [TYPE_ID_CARD]: <IDCard {...{t}} />
            }[type]}
        </div>
    );

}