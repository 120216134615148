const SELECTOR_ROW = row => `*[data-row = "${row}"]`;
const SELECTOR_SEAT = seat => `*[data-seat = "${seat}"]`;
const seatElement = (mapElement, row, seat) => {
    //return mapElement.querySelector(SELECTOR_ROW(row))?.querySelector(SELECTOR_SEAT(seat));

    //This need support for several rows elements of same row number
    for (const element of mapElement.querySelectorAll(SELECTOR_ROW(row))) { //Most optimal way, not iterating all elements, first found is enough
        const search = element.querySelector(SELECTOR_SEAT(seat));
        if (search) return search;
    }

    // return [...mapElement.querySelectorAll(SELECTOR_ROW(row))].reduce((prev, element) => {
    //     const search = element.querySelector(SELECTOR_SEAT(seat));
    //     if (search) return search;
    //     return prev;
    // }, undefined);

    // return [...mapElement.querySelectorAll(SELECTOR_ROW(row))].find(e => e.querySelector(SELECTOR_SEAT(seat)))?.querySelector(SELECTOR_SEAT(seat));
}

export default seatElement;