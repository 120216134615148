import React from "react";
import InfoElement from "../../InfoElement";
import { Col, Row } from "react-bootstrap";
import { UserIcon, QR as QRIcon, Price as PriceIcon } from 'components/Icons/Glyphs';
import CheckBox from "components/Inputs/CheckBox";

export default ({ tt, t, onPress }) => {
    return (
        <Row>
            <Col md={8}>
                <span className='zt-event-buy__add-friends__myticket__number'></span>
                <InfoElement bold icon={UserIcon} className='zt-event-buy__add-friends__myticket__label'>
                    <div style={{display: 'inline-block'}}>
                        <CheckBox value={true} onChange={e => onPress()}>{tt('imnotattending.checkbox', 'I am not attending')}</CheckBox>
                    </div>
                </InfoElement>
            </Col>
            <Col md={3}>
                <InfoElement icon={PriceIcon}>{t('common:price', {price: Number(0)})}</InfoElement>
            </Col>
        </Row>  
    );
}