import { useSelector } from 'react-redux';

export default function useIsAllFriendsAdded(){
    const { iamnotattending, friends } = useSelector(state => state.event.friends);
    const friendsQty = useSelector(state => state.event.friends.friends?.length);
    const ticketsQty = useSelector( state => state.event.tickets.qty);

    const friendsFilled = friends.every(f => ((+f.user.id) || (f.kid || f.user.id === 0)) && f.ticket.price !== null);// && f.user.first_name && f.user.last_name))

    return friendsFilled && (ticketsQty - (+friendsQty) - (iamnotattending ? 0 : 1) === 0);
}