export const ADDFRIENDS_GETTINFO_START = 'ADDFRIENDS_GETINFO_START';
export const ADDFRIENDS_GETTINFO_SUCCESS = 'ADDFRIENDS_GETINFO_SUCCESS';
export const ADDFRIENDS_GETTINFO_FAILURE = 'ADDFRIENDS_GETINFO_FAILURE';

export const ADDFRIENDS_FINDBYPHONE_START = 'ADDFRIENDS_FINDBYPHONE_START';
export const ADDFRIENDS_FINDBYPHONE_SUCCESS = 'ADDFRIENDS_FINDBYPHONE_SUCCESS';
export const ADDFRIENDS_FINDBYPHONE_FAILURE = 'ADDFRIENDS_FINDBYPHONE_FAILURE';

export const ADDFRIENDS_FRIEND_UPDATENAME = 'ADDFRIENDS_FRIEND_UPDATENAME';
export const ADDFRIENDS_FRIEND_UPDATEPAY = 'ADDFRIENDS_FRIEND_UPDATEPAY';

export const ADDFRIENDS_FRIEND_SETKID = 'ADDFRIENDS_FRIEND_SETKID';
export const ADDFRIENDS_FRIEND_SETPRICE = 'ADDFRIENDS_FRIEND_SETPRICE';

export const ADDFRIENDS_GETTICKETINFO_START = 'ADDFRIENDS_GETTICKETINFO_START';
export const ADDFRIENDS_GETTICKETINFO_SUCCESS = 'ADDFRIENDS_GETTICKETINFO_SUCCESS';
export const ADDFRIENDS_GETTICKETINFO_FAILURE = 'ADDFRIENDS_GETTICKETINFO_FAILURE';

export const ADDFRIENDS_ADD_FRIEND = 'ADDFRIENDS_ADD_FRIEND';
export const ADDFRIENDS_REMOVE_FRIEND = 'ADDFRIENDS_REMOVE_FRIEND';
export const ADDFRIENDS_RESET_FRIENDS = 'ADDFRIENDS_RESET_FRIENDS';

export const SET_I_AM_NOT_ATTENDING = 'SET_I_AM_NOT_ATTENDING';
export const RESET_I_AM_NOT_ATTENDING = 'RESET_I_AM_NOT_ATTENDING';