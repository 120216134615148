import * as actions from './constants';

import defaultTicket from './defaultTicket';
import friendReducer, { defaultFriendState } from './friendReducer';
import priceAdapter, { transformPriceTypes } from './priceAdaptor';

// price = {[typealias]: pirce, [typealias]: price... }
// prices = {priceseat1, priceseat2, ....}

const defaultFees = {
    admin: null, 
    service: null
};

const defaultUser = {
    register_type: null,
    types: []
}

const defaultState = {
    friends: [], // Array of friend
    iamnotattending: false,
    loading: false,
    ticket: defaultTicket,
    user: defaultUser,
    prices: null,
    fees: defaultFees, 
    reservedTime: 0,
    error: null
}

const compareObjects = function(o1, o2){
    for(var p in o1){
        if(o1.hasOwnProperty(p)){
            if(o1[p] !== o2[p]){
                return false;
            }
        }
    }
    for(var p in o2){
        if(o2.hasOwnProperty(p)){
            if(o1[p] !== o2[p]){
                return false;
            }
        }
    }
    return true;
};

const priceEqual = prices => prices.every(price => compareObjects(price, prices[0]));

export default function(state = defaultState, action) {
    const { type, payload = null, error = null, friendId = null } = action;
    
    if (friendId !== null) {
        return {
            ...state,
            friends: [...state.friends.map((friend, i) => i === friendId ? friendReducer(friend, action, state.prices) : friend)]
        }  
    }

    switch (type) {

        case actions.SET_I_AM_NOT_ATTENDING: {
            return {
                ...state,
                iamnotattending: true
            }
        }

        case actions.RESET_I_AM_NOT_ATTENDING: {

            return {
                ...state,
                friends: state.iamnotattending ? [] : state.friends,
                iamnotattending: false
            }
        }        

        case actions.ADDFRIENDS_GETTINFO_START: {
            return {
                ...state,
                error: null,
                loading: true
            }
        }

        case actions.ADDFRIENDS_GETTINFO_SUCCESS: {

            const { user, prices: pricesSources } = payload;
            const prices = transformPriceTypes(pricesSources);

            const equalPrices = priceEqual(prices); //If we got all same prices for all seats or not

            const { ticket_types, register_type, has_ticket=null } = user;

            const price = equalPrices && ticket_types?.length === 1 ? 0 : null;

            return {
                ...defaultState,
                user: {
                    ...defaultUser,
                    register_type,
                    has_ticket,
                    types: ticket_types
                },
                prices: priceAdapter(equalPrices ? [prices[0]] : prices),      
                ticket: {
                    ...defaultTicket,
                    type: ticket_types?.[0],
                    price
                },
                reservedTime : payload.reserved_time || 0,                          
                iamnotattending: !ticket_types?.length //If user haven't types - not be able to attend to the event                
            }
        }

        case actions.ADDFRIENDS_GETTINFO_FAILURE: {
            return {
                ...state,
                loading: false,
                error
            }
        }

        case actions.ADDFRIENDS_FRIEND_SETPRICE: {
            const { price = null, type = null } = payload || {};
            return {
                ...state,
                friends: state.friends,
                ticket: {
                    ...state.ticket,
                    price,
                    type
                }
            }
        }    

        case actions.ADDFRIENDS_ADD_FRIEND: {
            
            const uuid = (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();
            const kid = payload?.kid || false;

            return {
                ...state,
                friends: [...state.friends, {
                    ...defaultFriendState,
                    kid,
                    uuid,
                    ticket: {
                        ...defaultTicket,
                        price: null
                    }
                }]
            }
        }

        case actions.ADDFRIENDS_REMOVE_FRIEND: {
            const id = payload;
            return {
                ...state,
                friends: [...state.friends.filter((f, i) => i !== id)]
            }
        }

        case actions.ADDFRIENDS_RESET_FRIENDS: {
            return defaultState;
        }

        default: {
            return state;
        }
    }
}