import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export default ({ t }) => {

    const keys = ['p1', 'p2', 'p3'];

    return (
        <div className='zt-registration__document__success'>
            {keys.map((k, key) => <p {...{key}} className='zt-nav light'>
                <Trans
                    {...{t}}
                    i18nKey={`using_document.success.${k}`}
                    components={{ italic: <i />, b: <b />, highlight: <b style={{backgroundColor: 'white', color: '#b02518'}}/> }}
                />
            </p>)}
        </div>
    );
};

